.TableContainer{
    box-shadow: '0px 13px 20px 0px #80808029';
}

.MuiTableRow-root>* {
    padding: 10px !important;
}

.MuiTableRow-root.MuiTableRow-head>* {
    font-weight: bold !important;
}

.Table td,th {
    border: none !important;
}

.Table:first-child{
    border-radius: 0.7rem !important;
}

.status{
    padding: 6px;
    border-radius: 9px;
}

.details{
    color: #00b5ff !important;
}

@media screen and (max-width : 1200px){
    .Table{
        width: 170%;
        margin-top: 2rem;
    }
  }

  @media screen and (max-width : 768px){
    .Table{
       position: relative;
       display: flex;
       flex-direction: column;
       align-items: center;
       width: 18rem;
    }
   }