.compactCard {
    display: flex;
    flex: 1;
    height: 7rem !important;
    border-radius: 0.7rem;
    color: white;
    position: relative;
    cursor: pointer;
    padding: 1rem;
}

.compactCard:hover{
    box-shadow: none !important;
}

/* radialbar */

.radialBar{
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    gap: 1rem;
}

.CircularProgressbar-path {
    stroke: white !important;
    stroke-width: 12px !important;
    filter: drop-shadow(2px 4px 6px white);
}

.CircularProgressbar {
    width: 4rem !important;
    overflow: visible;
}

.CircularProgressbar-trail{
    display: none;
}

.CircularProgressbar-text{
    fill: white !important;
}

.radialBar>span{
    font-size: 17px;
    font-weight: bold;
}

/* detail */

.detail {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
}

.detail>span:nth-child(2){
    font-size: 22px;
    font-weight: bold;
}

.detail>span:nth-child(3){
    font-size: 12px;
}

/* expendedcard */

.expendedCard {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    left: 13rem;
    width: 60%;
    height: 70vh;
    z-index: 9;
    border-radius: 1rem;
    padding: 1rem;
}

.uiltimeIcon{
    display: flex;
    align-self: flex-end;
    cursor: pointer;
    color: white;
}

.expendedCard>span:nth-of-type(1){
color: white;
font-size: 26px;
font-weight: bold;
text-shadow: 0px 0px 15px white;
}

.expendedCard>span:nth-of-type(2){
    color: rgb(223,222,222);
    font-size: 16px;
}

.chartContainer{
    width: 70%;
}

@media screen and (max-width : 1200px){
  .expendedCard{
    top: 2rem;
    height: 70vh;
    left: 6rem;
  }
}

@media screen and (max-width : 768px){
    .expendedCard{
        top: 8rem;
        height: 50%;
        left: 15px;
        width: 80%;
      }
   }