.sidebar{
    display: flex;
    flex-direction: column;
    position: relative;
    padding-top: 4rem;
    transition: all 300ms ease;
}

.logo {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 5rem;
    font-size: 22px;
    font-weight: bold;
    gap: 1rem;
    height: 4%;
}

.logo>img {
    width: 3rem;
    height: 3rem;
}

.logo>span>span{
    color: var(--pink);
}

.menu {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    margin-top: 4rem;
}

.menuItem {
    display: flex;
    align-items: center;
    gap: 1rem;
    height: 2.5rem;
    margin-left: 2rem;
    position: relative;
    transition: all 300ms ease;
    font-size: 14px;
    border-radius: 0.7rem;
}

.menuItem:hover {
 cursor: pointer;
}

.active {
    background-color: var(--activeItem);
    margin-left: 0;
}

.active::before{
    content: '';
    width: 8px;
    height: 100%;
    background-color: var(--pink);
    margin-right: calc(1rem-8px);
}

.menuItem-bottom {
    position: absolute;
    bottom: 3.3rem;
    margin-left: 2rem;
}

.menuIconCls{
    display: none;
}

@media screen and (max-width : 1200px){
    .menuItem>span {
      display: none;
    };
    .logo {
        display: none !important;
    };
    .logo {
        display: none;
    }
}

@media screen and (max-width : 768px){
  .sidebar{
    position: fixed;
    z-index: 9;
    background: #ffe0e0;
    width: 55%;
    padding-right: 1rem;
    height: 100%;
  }
  .menuItem>span {
    display: block;
  };
  .logo {
    display: flex;
}
.menuItem-bottom {
    position: relative;
    margin-top: 2rem;
}

.menuIconCls{
    display: flex;
    position: fixed;
    top: 2rem;
    z-index: 9;
    background: #ffe0e0;
    padding: 10px;
    border-radius: 10px;
}

}